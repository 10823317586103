
export const WhereAppleTiles = {
    label: 'Apple @ Where',
    style: {
        version: 8,
        sources: {
            WhereAppleTiles: {
                type: 'raster',
                tiles: [
                    'https://tiles.where.tn/apple/{z}/{x}/{y}.jpg'
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 19,
            }
        },
        layers: [
            {
                id: 'WhereAppleTiles',
                type: 'raster',
                source: 'WhereAppleTiles',
            }
        ]
    }
};


export const WhereNokiaTiles = {
    label: 'Nokia @ Where',
    style: {
        version: 8,
        sources: {
            WhereNokiaTiles: {
                type: 'raster',
                tiles: [
                    'https://tiles.where.tn/Nokia/tiles/{z}/{x}/{y}.png'
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 19,
            }
        },
        layers: [
            {
                id: 'WhereNokiaTiles',
                type: 'raster',
                source: 'WhereNokiaTiles',
            }
        ]
    }
};

export const WhereGoogleTiles = {
    label: 'Google @ Where',
    style: {
        version: 8,
        sources: {
            WhereGoogleTiles: {
                type: 'raster',
                tiles: [
                    'https://tiles.where.tn/Google/steet/{z}/{x}/{y}.png'
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 19,
            }
        },
        layers: [
            {
                id: 'WhereGoogleTiles',
                type: 'raster',
                source: 'WhereGoogleTiles',
            }
        ]
    }
};

export const WhereGoogleSatellite = {
    label: 'Google Satellite @ Where',
    style: {
        version: 8,
        sources: {
            WhereGoogleSatellite: {
                type: 'raster',
                tiles: [
                    'https://tiles.where.tn/Google/satellite/{z}/{x}/{y}.png'
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 19,
            }
        },
        layers: [
            {
                id: 'WhereGoogleSatellite',
                type: 'raster',
                source: 'WhereGoogleSatellite',
            }
        ]
    }
};

export default {
    WhereAppleTiles,
    WhereNokiaTiles,
    WhereGoogleSatellite,
    WhereGoogleTiles,
}