<template>
  <div id="page" class="aside-main">
    <aside>
      <input type="number" v-model="tileZoom">
      <select v-model="provider" style="width: 120px;">
        <option v-for="p in providers" :key="p" :value="p">{{p}}</option>
      </select>
      <button @click="loadTiles()">Draw</button><br>
      <table class="mouseTiles">
        <tr>
          <th>Z</th>
          <th>X</th>
          <th>Y</th>
        </tr>
        <tr v-for="z in 19" :key="z">
          <td>{{z}}</td>
          <td>{{mouseX(z)}}</td>
          <td>{{mouseY(z)}}</td>
        </tr>
      </table>
      Download Queue: {{queue}}<br>
      <button v-if="queue" @click="cancelQueue">cancel</button>
    </aside>

    <main>
      <MglMap 
        :accessToken="accessToken" 
        :mapStyle="mapStyle" 
        :attributionControl="false"
        :attribution="false"
        :hash="true"
        :tileBoundaries="true"
        @load="onMapLoad"
      >
        <MglFullscreenControl position="top-right" />
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
        <MglAttributionControl position="bottom-right" />
        <MglScaleControl position="bottom-left" />
      </MglMap>
    </main>
  </div>

</template>

<script>
import {
  MglMap,
  MglAttributionControl,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl
} from "vue-mapbox";

// import axios from 'axios'

import MapboxDraw from "@mapbox/mapbox-gl-draw";

import { AllStyles } from "@/mapbox/styles";
import HackedTiles from "@/mapbox/styles/hacked";
import tools from '../tools';
import MyControls from "../mapbox/controls";

export default {
  components: {
    MglMap,
    MglAttributionControl,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl
  },
  data() {
    return {
      accessToken: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2pjZW0weGoxMWZkZDJ3cGVmYmZkbHVreiJ9.0dFhFtJJUEN2ziihdjzNwQ',
      mapStyle: AllStyles['WhereStreets'].style,
      amenities: [],
      zoom: 0,
      bbox: '',
      lat: 0,
      lng: 0,
      tileZoom: 16,
      tiles: [],
      mouseLng: 0,
      mouseLat: 0,
      providers: ['apple', 'Nokia/tiles', 'Google/satellite', 'Google/tiles'],
      provider: 'apple',
      queue: 0,
      queueInterval: null, // ms
    };
  },
  mounted() {
    this.updateQueue();
  },
  beforeDestroy() {
    clearTimeout(this.queueTimer);
  },
  methods: {
    setQueue(val) {
      clearTimeout(this.queueTimer);
      this.queue = val;
      this.updateQueue();
    },
    cancelQueue() {
      this.$api.post('https://api.where.tn/tiles/cancel');
    },
    updateQueue() {
      this.$api.get('https://api.where.tn/tiles/queue').then(j => {
        this.queue = j.data.total;
        this.queueInterval = this.queue ? 1000 : 10000;
        this.queueTimer = setTimeout(this.updateQueue, this.queueInterval);
      });
    },
    onMapLoad(event) {
      this.map = event.map;

      // faster scroll with mouse wheel
      this.map.scrollZoom.setWheelZoomRate(1/100);

      // add myControls
      this.map.addControl(new MyControls.StyleSelectorControl({ styles: Object.values({ ...AllStyles, ...HackedTiles }), }), 'top-left');
      this.map.addControl(new MyControls.LanguageSelectorControl());
      this.map.addControl(new MyControls.ShowTilesCoordinatesControl());

      // update lng,lat under mouse
      this.map.on('mousemove', e => {
        this.mouseLng = e.lngLat.wrap().lng;
        this.mouseLat = e.lngLat.wrap().lat;
      });

      // Draw tools
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true
        }
      });
      this.map.addControl(draw);

      // drawing a line triggers download at current zoom
      this.map.on('draw.create', (e) => {
        const geojson = draw.get(e.features[0].id);

        this.$api
          .post(`https://api.where.tn/tiles/download?tiles=${this.provider}&z=${this.tileZoom}&Z=${this.tileZoom}`, { geojson })
          .then(j => {
            console.log(j)
            this.setQueue(j.data.tiles.length)
          })
      });

      if (this.map.getCenter().lat < 0.01) {
        this.map.fitBounds([[0, 29.25], [19.8, 37.98]]);
      }

    },
    loadTiles() {
        this.$api.get(`https://api.where.tn/tiles?tiles=${this.provider}&z=${this.tileZoom}`, { responseType: 'arraybuffer' })
        .then(j => { 
          const buf = Buffer.from(j.data, 'binary');
          const l = buf.length / 6;
          const tiles = [];
          for (let i=0; i<l; i++) {
            const x = (buf[(i*6)]<<16) + (buf[(i*6)+1]<<8) + buf[(i*6)+2];
            const y = (buf[(i*6)+3]<<16) + (buf[(i*6)+4]<<8) + buf[(i*6)+5];
            tiles.push([this.tileZoom, x, y]);
          }
          this.tiles = tiles;
          this.drawTiles();
        });
    },
    drawTiles() {
      const map = this.map;
  
      const features = [];
      console.time('tiles.geojson');
      // Loop over all of the pixels
      for (let i=0; i<this.tiles.length; i++) {
        const x = this.tiles[i][1];
        const y = this.tiles[i][2];
        const bbox = tools.xyToBbox(this.tileZoom, x, y);
        features.push({
            "type": "Feature",
            "geometry": {
              "type": "Polygon",
              "coordinates": [[[bbox.minLng, bbox.minLat], [bbox.maxLng, bbox.minLat], [bbox.maxLng, bbox.maxLat], [bbox.minLng, bbox.maxLat], [bbox.minLng, bbox.minLat]]]
            }
        })
      }
      console.timeEnd('tiles.geojson');

      const geojson = {
        "type": "FeatureCollection",
        "features": features
      }

      if (map.getSource('tiles')) {
        map.removeLayer('tiles')
        map.removeSource('tiles')
      }

      map.addSource('tiles', { 'type': 'geojson', data: geojson, tolerance: 0.00001 });
      map.addLayer({
        'id': 'tiles',
        'source': 'tiles',
        'type': 'fill',
        'paint': {
          'fill-color': '#f00',
          'fill-opacity': 0.6,
        }
      });
    },
    mouseX(z) {
      return tools.lngLatZoomToTileXY(this.mouseLng, this.mouseLat, z).x;
    },
    mouseY(z) {
      return tools.lngLatZoomToTileXY(this.mouseLng, this.mouseLat, z).y;
    }
  },
  computed: {
  },
  watch: {
  }
};
</script>

<style>
@import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "../../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

</style>
<style scoped lang="scss">

.map-overlay {
  font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  position: absolute;
  width: 25%;
  bottom: 100px;
  left: 0;
  padding: 10px;

  .map-overlay-inner {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin: 0 0 10px;
  }

  input {
    background-color: transparent;
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0;
    cursor: ew-resize;
  }
}

table.mouseTiles {
  margin-top: 20px;
  font-size: 11px;
  td {
    text-align: right;
  }
}
</style>